

































































import { Component, Vue } from 'vue-property-decorator';
import { Meta } from '@sophosoft/vue-meta-decorator';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card, NumberCard } })
export default class DealerDashboard extends Vue {
  @Meta
  private getMetaInfo() {
    return {
      title: this.$it('menu.dashboard.title', 'Dashboard'),
    };
  }
}
