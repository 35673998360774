













































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Card extends Vue {
  @Prop({ type: String, default: null }) protected title!: string | null;
  @Prop({ type: String, default: null }) protected info!: string | null;
  @Prop({ type: Boolean, default: true }) protected guttered!: boolean;
  @Prop({ type: Boolean, default: true }) protected bordered!: boolean;
  @Prop({ type: Boolean, default: null }) protected collapsed!: boolean|null;
  @Prop({ type: Boolean, default: false }) protected titleInset!: boolean;

  @Prop({ type: Boolean, default: false }) protected menuCollapsed!: boolean|null;
  @Prop({ type: Boolean, default: false }) protected bodyCollapsed!: boolean|null;

  @Prop({ type: Boolean, default: false }) protected hasError!: boolean;

  @Prop({type: Boolean, default: false}) protected primary!: boolean;
  @Prop({type: Boolean, default: false}) protected secondary!: boolean;
  @Prop({type: Boolean, default: false}) protected success!: boolean;
  @Prop({type: Boolean, default: false}) protected warning!: boolean;
  @Prop({type: Boolean, default: false}) protected danger!: boolean;
  @Prop({type: Boolean, default: false}) protected purple!: boolean;
  @Prop({type: String, default: null}) protected colorCode!: string | null;

  get classes() {
    return {
      'card-primary': this.primary,
      'card-guttered': this.guttered,
      'card-bordered': this.bordered,
      'card-collapsed': this.isCollapsed,
      'card-collapsable': this.isCollapsable,
    };
  }

  get bodyClasses() {
    return {
      'card-primary': this.primary || this.colorCode,
      'card-secondary': this.secondary,
      'card-success': this.success,
      'card-warning': this.warning,
      'card-danger': this.danger,
      'card-purple': this.purple,
      'has-error': this.hasError,
    };
  }

  get codeColor() {
    if (this.colorCode) {
      return 'background: ' + this.colorCode + ';' + 'borderColor: ' + this.colorCode + ' !important;';
    }
    return;
  }

  private isCollapsed: boolean = false;
  private isCollapsable: boolean = false;

  private created() {
    this.isCollapsable = this.collapsed !== null;
    this.isCollapsed = this.collapsed === true;
  }

  private toggle() {
    if (this.isCollapsable) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}
